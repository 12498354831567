@import "../../_variables.scss";

.error-popup {
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-direction: row;
  padding: 1.063rem 1rem 0rem 1.188rem;
  align-items: flex-start;
  gap: 0.625rem;
  width: 24.5rem;
  height: 5rem;
  left: 39rem;
  top: 5.25rem;
  background: $color-primary-light;
  border: 0.063rem solid $color-primary-dark;
  box-shadow: 0rem 0.5rem 1rem rgba(39, 40, 51, 0.2);
  border-radius: 0.25rem;
}