@import "../../../_variables.scss";

.lab-sop-container {
    padding: 1.5rem 3.375rem 0rem 2.563rem;

    .lab-sop-wrapper {
        .lab-sop-heading {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.8rem;
            color: $color-black;
            letter-spacing: 0.016rem;
        }
    }

    .sop-rule {
        margin-top: 2.188rem;
        padding-left: 1.063rem;

        .rule-label {
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.5rem;
            color: $color-black;
        }

        .rule-paragraph {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.313rem;
            color: $color-secondary;
        }
    }
}