@import "../../../../_variables.scss";
.workup-root .filter-right-sec .filter-count{
    font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.938rem;
      color: $color-white;
      margin-right: 0.313rem;
      background-color: $color-primary;
      min-width: 0.938rem;
      border-radius: 50%;
}
.lab-item{
  width: 100%;
  display: flex;

  svg{
    margin-left: auto;
  }
}
