@import "../../_variables.scss";
.error-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  gap: 1.875rem;
  overflow: hidden;
  margin-left: 35%;
  min-width: 37.5rem;
  .login-span{
    display: flex;
    .login-error{
    color: $color-primary;
    padding:0 0.3rem 0 0.3rem;
    &:hover{
      cursor: pointer;
    }
  }
  }
  .status-msg {
    font-weight: 700;
    font-size: 2.188rem;
    line-height: 2.563rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.016rem;
    color: $color-secondary;
  }
  .error-descr {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
    letter-spacing: 0.016rem;
    color: $color-darker-gray;
  }
  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.009rem;
    color: $color-darker-gray;
    span {
      color: $color-primary;
    }
  }
}
