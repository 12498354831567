@import "../../_variables.scss";

.filter-root {
  display: flex;
  padding: 1rem 1.5rem 1rem 1.5rem;
  box-sizing: border-box;

  .left-sec {

    .MuiTextField-root.search {

      .MuiOutlinedInput-root {
        border-radius: 3.125rem;
        width: 25rem;
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 0.063rem solid $color-gray;
        }
      }

    }
  }

  .filter-right-sec {
    margin-left: auto;

    .filter-count {
      font-weight: 500;
      font-size: 0.625rem;
      line-height: 0.938rem;
      color: $color-white;
      margin-right: 0.313rem;
      background-color: $color-primary;
      min-width: 0.938rem;
      border-radius: 50%;
    }

    .MuiButtonBase-root {
      border: 0.063rem solid $color-primary;
      color: $color-primary;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 500;
      padding: 0.625rem 2rem;
      border-radius: 0.312rem;
      text-transform: none;
    }
  }
}

.MuiTextField-root.search {
  .MuiOutlinedInput-input {
    color: $color-dark-gray;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.313rem;
    border-color: none;
  }
  .Mui-focused {
    border-color: none ;
    .MuiOutlinedInput-notchedOutline {
      border: 0.063rem solid $color-gray;
    }
    .MuiOutlinedInput-notchedOutline:focus {
      border: none ;
    }
  }
}

.MuiCalendarPicker-root {
  .MuiSvgIcon-root {
    color: $color-secondary;
    font-weight: 500;
  }

  .MuiTypography-root {
    color: $color-secondary;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .MuiPickersDay-root {
    color: $color-secondary;
    font-weight: 400;
  }

  .MuiPickersDay-root.Mui-selected {
    background-color: $color-primary  !important; //!important
    color: $color-white;
  }

  .MuiButtonBase-root.MuiPickersDay-today {
    background-color: $color-white  !important; //!important
    color: $color-primary;
    border: 1px solid $color-primary ;

    &:hover {
      background-color: $color-white  !important; //!important
    }
  }
}
.filter-custom-modal{
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    margin-top: 0;
  }
.filter-modal-box {
  background-color: $color-white;
  box-shadow: 24;
  border-radius: 1rem;
  padding: 1.5rem;

  .filter-wrapper {
    display: flex;
    padding-bottom: 1.25rem;
    border-bottom: 0.063rem solid $color-gray;

    span {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-secondary;   
    }

    Button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-secondary;
    }

    .clear-label {
      margin-left: auto;
      font-weight: 400;
    }

    .disable-clear-label {
      margin-left: auto;
      font-weight: 400;
      color: $color-grey-disabled;
      cursor:default;
    }

    .apply-filter-lable {
      margin-left: 1.5rem;
      color: $color-primary;
    }

    .disable-filter-lable {
      color: $color-red-disabled;
      margin-left: 1.5rem;
      cursor:default;
    }
  }

  .filter-status-wrapper {
    padding: 2rem 0;

    .status-label {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.313rem;
      margin-bottom: 1.25rem;
      color: $color-secondary;
      
    }

    .status-filter-labels-wrapper {
      display: flex;
    }

    span {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      padding: 0.25rem 0.5rem;
      border-radius: 62.438rem;
      background-color: $color-light-gray;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }

    .status-NEW {
      background-color: $color-info-light;
    }

    .status-IN-PROGRESS {
      background-color: $color-warning;
    }

    .status-IN-REVIEW {
      background-color: $color-rating;
    }

    .status-COMPLETED {
      background-color: $color-success;

    }
  }
}

.filter-date-wrapper {

  .MuiFormControl-root {
    width: 50%;
    background-color: $color-light-gray;
  }

  .MuiSvgIcon-root {
    color: $color-primary;
    cursor: pointer;
  }

  .date-label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin-bottom: 1rem;
    color: $color-secondary;
  }

  label{
    font-size: 0.875rem;
  }

  .date-picker-label {
    display: flex;

    .MuiFormControl-root:first-child {
      margin-right: 1rem;
    }

    .MuiInputLabel-formControl,
    .MuiOutlinedInput-root {
      font-size: 0.875rem;
      color: $color-lightest-grey;
    }

    .filter-date-wrapper {
      background: $color-light-gray;
      padding: 1rem;
      border: 0px;
      border-radius: 0.313rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  .date-error-label {
    color: $color-primary;
  }
}

.lab-menu-item {
  background-color: $color-white  !important;
}

.lab-item {
  width: 100%;
  display: flex;
  justify-content: space-between;


  & .lab-item-text {
    &.selected {
      color: $color-secondary;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.313rem;
    }
  }
}


.filter-lab-wrapper {
  padding: 2rem 0;

  .lab-label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin-bottom: 1rem;
    color: $color-secondary;

  }

  .lab-select-wrapper {
    width: 100%;
  }

  .MuiSvgIcon-root {
    fill: $color-primary;
  }

  .MuiSelect-select {
    em {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.735rem;
      color: $color-darker-gray;
      font-style: normal;
    }
  }
}

.filter-panel-wrapper {
  .panel-label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.313rem;
    color: $color-secondary;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
  }

  .panel-container {

    display: block;

    .Mui-checked .MuiSvgIcon-root {
      fill: $color-primary;
    }

    .panel-item {
      width: 45%;
    }

    .MuiSvgIcon-root {
      color: $color-secondary;
    }
  }
}
}
@media screen and (min-width: 1441px) and (max-width: 1660px){
  .filter-root {
    padding:  1.5rem 1.5rem 1.5rem 1.5rem;
  }   
}

@media screen and (min-width: 1661px){
  .filter-root {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }  
}
 
@media screen and (max-width: 1200px){
  .filter-root {
    width: 100%;   
      .left-sec {   
        .MuiTextField-root.search {   
          .MuiOutlinedInput-root {
            width: auto;
          } 
        }
      }
    }
}
