@import "../../../_variables.scss";

.name-change-drawer{
    .panel-box{
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 34.5rem;
      .box-header{
        display: flex;
        padding: 1.563rem 1rem;
        border-bottom: 0.063rem solid $color-medium-gray;
        
        .title{
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.5rem;
          color: $color-secondary;
        }
        .close-icon{
          color: $color-secondary;
          margin-left: auto;
          cursor: pointer;
        }
      }
      .box-body{
        flex: 1;
        padding: 1.5rem;
        .input-wrapper{
            .name-label{
            }
            .name-input{
                margin: 1rem 0;
                input{
                    padding: 0.813rem 0.75rem;
                    width: 16.25rem;
                    color: $color-dark-gray;
                    background: $color-light-gray;
                    border-radius: 0.313rem;
                }
            }
            .note-msg{
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.125rem;
                color: $color-lightest-grey;
            }
            .MuiFormControlLabel-root{
                .MuiCheckbox-root{
                    svg{
                        color: $color-primary;
                    }
                }
                .MuiFormControlLabel-label{
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.063rem;
                    color: $color-black;
                }
            }
        }
      }
      .box-footer{
        display: flex;
        justify-content: center;
        padding: 1.5rem;

        .btn {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.125rem;
            text-align: center;
            padding: 0.813rem 2rem;
            border: 0.063rem solid $color-primary;
            border-radius: 0.313rem;
            cursor: pointer;

            &.disabled{
                opacity: 0.5;
            }
            
            &.cancel-btn {
                margin-right: 1.5rem;
                color: $color-primary;
                background: $color-white;
                border-color: $color-primary;
            }
            &.save-btn {
                color: $color-white;
                background: $color-primary;
            }
        }
      }
    }
  }