@import "../../_variables.scss";

.terms-of-service-container {
    margin-left: 2.563rem;
    margin-top: 1.5rem;
    margin-right: 3.875rem;
    margin-bottom: 2.938rem;

    .terms-of-service-header {
        .terms-of-use-title {
            font-weight: 500;
            font-size: 2.188rem;
            line-height: 2.563rem;
            display: flex;
            align-items: center;
            letter-spacing: 0.016rem;
            color: $color-black;
        }

        .welcome-note {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.125rem;
            color:$color-black;
        }
    }

    .terms-of-service-accept-agreement {
        margin-top: 4.5rem;

        .effective-date {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.188rem;
            color: $color-black;
        }

        .term-of-service-information {
            margin-top: 1.313rem;
            border: 0.063rem solid $color-gray;
            padding-top: 1.5rem;
            padding-left: 1.5rem;
            padding-right: 3rem;

            .topic {
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 1.75rem;
                color: $color-black;
            }

            .paragraph {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.313rem;
                color: $color-secondary;
            }
        }
    }

    .accept-button-wrapper {
        display: flex;
        margin-top: 2.563rem;

        Button {
            padding: 0.813rem 2rem;
            margin-left: auto;
            text-transform: none;
            background: $color-primary;
            border-radius: 0.313rem;
            color: $color-white;
        }
    }
}