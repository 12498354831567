@import "../../../variables.scss";
.classification-tab-autocontrol {
  display: flex;
  flex-direction: row;

  .values-row {
    display: flex;
    
    table{
      border-collapse: collapse;
      border-radius: 0.25rem;
      border: 0.063rem solid $color-gray;
      
      td{
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 99.6%;
        text-align: center;
        padding: 0.594rem;
        color: $color-secondary;
        border: 0.063rem solid $color-gray;
        min-width: 3.2rem;
      }
      .thead{
        tr{
          td{
            font-weight: 500;
            line-height: 1.25rem;
            background: $color-medium-gray-light;
          }
        }
      }
      .tbody{
        tr{
          td{
            background: $color-white;
          }
          &.col-data{
            height: 2.24rem;
          }
        }
      }
    }
  }

  .label-row {
    margin-top: 1rem;
    margin-left: 1rem;
    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.063rem;
      color: $color-secondary;
      margin: 0;
      max-width: 26.688rem;
      &:not(:last-child){
        margin-bottom: 1.5rem;
      }
    }
  }
}
