@import "../../../../variables";

.workup-test-detail-container {
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  .add-workup-title{
    font-size: 2.188rem;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin-bottom: 2rem;
    line-height: 2.563rem;
    color: #000000;
  }
    .test-info {
      display: flex;
      text-align: left;
      padding: 1rem;
      background: $color-light-gray;
      border-radius: 1rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: $color-dark-gray;
      
    }
    .test-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0rem;
      flex: auto;
    }
    .panel-type {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $color-secondary;
      margin-top: 0.25rem;
    }
    .test-data {
      font-size: 14px;
      line-height: 21px;
      color: $color-secondary;
      margin-top: 4px;
    }
    .add-workup-information{
      
      h4{
        font-weight: 500;        
        font-size: 1rem;
        margin-top: 2rem;       
        color: $color-secondary;
      }
      p{
        font-weight: 400;
        font-size: 1rem;
        margin-top: 1rem;
        line-height: 1.5rem;
        color: #748492
      }
    
    }
  }