@import "../../_variables.scss";

.box-wrapper{
    display: flex;
    color: $color-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0 2.188rem 0;
    box-sizing: border-box;
    .sample-unlink-btn {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $color-primary;
        text-transform: none;
        display: flex;
        margin-left: auto
    }
    
    span{
        color:$color-dark-gray;
        
    }
    .MuiPagination-text {
        .MuiPagination-ul {
            .MuiPaginationItem-circular.Mui-selected {
                background: none;
                color: $color-primary;
            }
        }
    }
}


@media screen and (min-width: 1441px) and (max-width: 1660px){     
    .box-wrapper{
    padding: 1rem 0 2.188rem 0;
    }
}
@media screen and (min-width: 1661px){     
    .box-wrapper{
    padding: 1.5rem 0 2.188rem 0;
    }
}
    