@import "../../_variables.scss";
.MuiInputBase-root-MuiFilledInput-root {
  border-bottom: 0rem;
}
.css-10g5aos-MuiTypography-root-MuiDialogContentText-root {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  display: flex;
  align-items: center;
  color: $color-secondary;
}
.create-workup-container {
  margin-top: 1.5rem;
  margin-left: 1.563rem;
  margin-right: 1.563rem;
  margin-bottom: 3.563rem;

  .optional {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    display: flex;
    align-items: center;
    color: $color-primary;
    padding-left: 0.313rem;
  }
  .button {
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 1rem;
    text-align: center;
    display: flex;
    padding-top: 0.813rem;
    padding-bottom: 0.813rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 0.313rem;
    border: 1px solid $color-primary;
    background-color: $color-white;
    color: $color-primary;
  }
  b {
    font-weight: 500;
    font-size: 1rem;
    line-height: 0px;
    margin-top: 1.5rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    color: $color-secondary;
  }
  .create-workup-header {
    span {
      color: $color-black;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 1.813rem;
      display: flex;
      align-items: center;
      letter-spacing: 0.016rem;
    }
    p {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #748492;
      padding-bottom: 3rem;
    }
  }
  .workup-information {
    b {
      padding-bottom: 1.5rem;
    }
    .MuiBox-root.workup-input {
      .MuiFormControl-root {
        display: block;
        .MuiFilledInput-root {
          display: block;
          padding: 0.813rem 0.75rem;
          gap: 0.625rem;
          width: 37.875rem;
          height: 3rem;
          background: $color-light-gray;
          border-radius: 0.313rem;
          padding-bottom: 3rem;
          border-bottom: none;
          .MuiFilledInput-input::after {
            border: none;
          }
        }
      }
    }
    .workup-label {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.063rem;
      display: flex;
      align-items: center;
      color: $color-secondary;
      padding-bottom: 0.375rem;
      #mandatory {
        color: $color-primary;
      }
    }
  }
  .patient-information-container {
    .patient-info {
      display: flex;
      text-align: left;
      padding: 1.5rem;
      background: $color-light-gray;
      border-radius: 1rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: $color-dark-gray;
    }
    #data {
      font-size: 1rem;
      line-height: 1.313rem;
      color: $color-secondary;
      margin-top: 0.25rem;
    }
    .patient-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      flex: auto;
    }
  }
  .test-info-container {
    .test-info {
      display: flex;
      text-align: left;
      padding: 1rem;
      background: $color-light-gray;
      border-radius: 1rem;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: $color-dark-gray;
      margin-bottom: 1rem;
    }
    .test-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      flex: auto;
      width: 20%;
    }
    .status {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      padding: 0.25rem 0.5rem;
      border-radius: 62.438rem;
    }
    #data {
      font-size: 1rem;
      line-height: 1.313rem;
      color: $color-secondary;
      margin-top: 0.25rem;
    }
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border-radius: 0.313rem;
    padding-top: 0.5rem;
    .cancel {
      padding: 0.813rem 2rem;
      background: $color-white;
      border: 1px solid $color-white;
      color: $color-primary;
    }
    .submit {
      border: 1px solid $color-primary;
      padding: 0.813rem 1.031rem;
      gap: 0.5rem;
      color: $color-white;
      margin-left: 1.5rem;
      text-transform: none;
      border-radius: 0.313rem;
      &:hover {
        background: $color-primary;
        text-transform: none;
      }
    }
  }
}
