@import "../../../variables.scss";

.logout-wrapper {
    position: fixed;
    top: 40%;
    left: 35%;
    right: 35%;
    bottom: 40%;
    width: 30%;
    height: 10rem;
    z-index: 999;
    opacity: 1;

    .card-content {
        margin-right: auto;
        margin-left: auto;
        width: 28.125rem;
        border-radius: 0.313rem;
        padding: 0.625rem;
        text-align: center;

        .logout-header {
            display: flex;
            direction: row;
            align-items: center;
            position: relative;
            bottom: 1.5rem;
            border-bottom: 0.091rem solid $color-light-white;
            line-height: 1.375rem;

            p {
                color: $color-secondary;
                font-size: 1.125rem;
                font-weight: 400;
                margin-left: 0.5rem;
            }

            .close-icon {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .logout-content {
            justify-content: start;
            text-align: start;
            position: relative;
            color: $color-secondary;
            font-size: 0.875rem;
            line-height: 1.3rem;
            font-weight: 400;
            padding-bottom: 2rem;
        }

        .logout-action {
            justify-content: end;
            position: relative;
            font-size: 0.875rem;

            .cancel-btn {
                padding: 0.5rem 2rem;
                background: $color-white;
                border-radius: 0.313rem;
                color: $color-secondary;
                border: 0.0625rem solid $color-secondary;
                text-transform: none;
                line-height: 1.125rem;

            }

            .accept-btn {
                padding: 0.5rem 2rem;
                background: $red;
                border-radius: 0.313rem;
                color: $color-white;
                text-transform: none;
                line-height: 1.125rem;

            }

        }
    }
}