@import "../../_variables.scss";
.homapage-header{
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation4 {
  box-shadow: none;
  .MuiToolbar-root {
    height: 3.25rem;
    min-height: 3.25rem;
    background-color: $color-primary;

    .MuiButtonBase-root {
      .MuiBadge-root {
        .notification-icon {
          font-size: 1.5rem;
        }
        .MuiBadge-badge{
          background-color: $color-secondary;
          color: $color-white;
          font-size: 0.625rem;
          line-height: 150%;
        }
      }

      .user-avatar {
        font-size: 1.5rem;

      }

    }

    .demo-purpose{
      margin-left: 5rem;
      font-size: 0.688rem;
     
    }
    .header-icon-name-section{
      display: flex;
    }
    .user-details {
      padding: 1rem 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      :hover{
        cursor: pointer;
      }
      .user-name {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $color-white;
      }

      .user-destination {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.125rem;
        color: $color-white;
      }
    }

    .login-button {
      cursor: pointer;
      margin: 0.75rem
    }
  }

  .MuiListItemIcon-root {
    color: $color-black;
  }
}
}