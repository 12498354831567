@import "../../variables.scss";
.card-root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 30%;
    margin: auto;
    height: 10rem;
    z-index: 999;
    opacity: 1;

  .card-section {
    margin-right: auto;
    margin-left: auto;
    width: 28.125rem;
    border-radius: 0.313rem;
    padding: 0.625rem;
    text-align: center;

    .warning-header {
      display: flex;
      direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      bottom: 1.5rem;
      border-bottom: 0.091rem solid $color-light-white;
      p{
        color: $color-secondary;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 500;
      }
    }
    .check-card {
      justify-content: start;
      text-align: start;
      position: relative;
      color: $color-secondary;
        font-size: 0.875rem;
        line-height: 1.3rem;
        font-weight: 400;
    }
    .card-action {
      justify-content: end;
      position: relative;
      .cancel-btn {
        padding: 0.5rem 1.125rem;
        gap: 0.5rem;
        background: $color-white;
        border-radius: 0.313rem;
        color: $color-secondary;
        border: 0.0625rem solid $color-secondary;
        text-transform: none;

      }
      .accept-btn {
      padding: 0.5rem 1.125rem;
      gap: 0.5rem;
      background: $red;
      border-radius: 0.313rem;
      color: $color-white;
      text-transform: none;
     }
      
    }
  }
}

