@import "../../../_variables.scss";

.manage-accout {
    margin-top: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    a,
    a:link {
        text-decoration: none;
        color: inherit
    }

    .pwd-box-note {
        font-weight: 400;
        font-size: 0.875rem;
        color: $color-primary-blueGrey;
        line-height: 1.3125rem;
    }

    .manage-acc {
        color: $color-black;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 2.5625rem;
    }

    .acc-preference {
        margin-top: 1.5rem;

        .acc-preference-box {
            border: 0.0625rem solid $color-medium-gray;
            margin-top: 1.5rem;
            padding: 0.906rem 4rem
        }

        .account-heading {
            color: $color-black;
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.775rem;
        }

        .change-pwd {
            color: $color-secondary;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.3125rem;
        }
    }

    .notification-box {
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        .notification-box-heading {
            color: $color-black;
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.775rem;
        }

        .new-results-box {
            border: 0.0625rem solid $color-medium-gray;
            margin-top: 1.5rem
        }

        .result-box-note {
            color: $color-secondary;
            font-weight: 400;
            font-size: 0.875rem;
            padding-bottom: 1.188rem;
            padding-top: 0.938rem;
            margin-left: 5rem;
            line-height: 1.036rem;

            .toggle-switch {
                float: right;
                bottom: 0.675rem;
            }

            .switch-note {
                color: $color-primary-blueGrey;
                float: right;
                margin-right: 4.875rem;
            }
        }

        .status-change-box {
            border: 0.0625rem solid $color-medium-gray
        }

        .inApp-note-box {
            border: 0.0625rem solid $color-medium-gray;
            background-color: $color-light-gray
        }

        .status-box-note {
            color: $color-secondary;
            font-weight: 400;
            font-size: 0.875rem;
            padding-bottom: 1.188rem;
            padding-top: 0.938rem;
            margin-left: 5rem;
            line-height: 1.036rem;

            .toggle-switch {
                float: right;
                bottom: 0.675rem;
            }

            .switch-note {
                float: right;
                margin-right: 4.875rem;
                color: $color-primary-blueGrey;
            }
        }

        .notification-box-content {

            .new-results,
            .status-change {
                color: $color-secondary;
                margin-left: 4rem;
                padding-top: 1.531rem;
                padding-bottom: 1.531rem;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.3125rem;
            }
        }

    }

    .button-div {
        display: flex;

        .save {
            color: $color-white;
            background-color: $color-primary;
            font-size: 0.875rem;
            padding: 0.813rem 3.094rem;
            margin-bottom: 3.563rem;
            text-transform: none;
            margin-left: auto;

            &:hover {
                background-color: $color-primary;
            }
        }
    }
}