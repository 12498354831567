.homepage-layout-root{
    min-width: 1280px;
    .homepage-main-wrapper{
        display: flex;

        .homepage-sidebar{
            width: 4.5rem;
        }
        .homepage-right-block-wrapper{
            width: 100%;
            display: flex;
            min-height: 100vh;
            flex-direction: column;
            justify-content: space-between;
    
        }
    }
}