@import "../../_variables.scss";

.homepage-sidebar{
  border-right: 1px solid $color-gray;

  .sidebar-root {
    display: flex;
    flex-direction: column;
    padding: 31px 15px 19px 15px;
    gap: 20px;
    position: sticky;
    position: -webkit-sticky;
    top: 0rem;
    z-index: 222;
  
    .side-nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 3px;
      svg {
        width: 20;
        height: 20;
        fill: none;
      }
      a {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: $color-secondary;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        span{
          margin-top: 6px;
        }
      }
      a.active{
        color: $color-primary;
        border-right: 0.3rem solid $color-primary;
        width: 4.5rem;
      }
    }
  }
}