@import '../../../../_variables.scss';
.edit-workup-singlepage{
    .MuiPaper-root.MuiPaper-elevation{
         box-shadow: 0rem 0.1rem 0.2rem $color-black-light;
         ul{
            li{
                a{
                    color:inherit;
                    width: 100%;
                }
            }
         }
    }
}
