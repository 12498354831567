@import "../../_variables.scss";

.panel-box {
    background-color: $color-white;
    max-width: 34.5rem;

    .panel-heading {
        font-size: 1rem;
        font-weight: 600;
        padding: 1.563rem 1rem;
        border-bottom: 0.063rem solid $color-medium-gray;
        line-height: 1.5rem;
        color: $color-secondary;
        .close-btn {
            float: right;
            margin-top: 0.125rem;
            cursor: pointer;
        }
    }

    .product-data {
        padding: 1.5rem;
        border-bottom: 0.063rem solid $color-gray;
        font-size: 1.125rem;
        font-weight: 400;
        color: $color-black;
        line-height: 1.375rem;
        letter-spacing: 0.009rem;
        .reagent-data,
        .product-panel-data {
            margin-bottom: 1rem;
        }
    }

    // .cell-data {
    //     padding: 1.5rem;
    //     border-bottom: 0.063rem solid $color-gray;
    //     font-weight: 400;

    //     .error-icon {
    //         margin-bottom: 1rem;
    //         color: $color-secondary
    //     }

    //     .second-error-icon {
    //         color: $color-secondary
    //     }
    // }

    .static-data {
        padding: 1.5rem;
        border-bottom: 0.063rem solid $color-gray;
        color: $color-black;
        line-height: 1.313rem;
        font-weight: 400;

        .static-info {
            margin-bottom: 1rem;
        }
    }

    .additional-details {
        padding: 1.5rem;
        border-bottom: 0.063rem solid $color-gray;

        .additional-panel-heading {
            font-size: 1.125rem;
            font-weight: 400;
            margin-bottom: 1rem;
            color: $color-black;
            letter-spacing: 0.009rem;
            line-height: 1.375rem;
        }

        .language-data {
            margin-top: 1rem;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.313rem;
            color: $color-black
        }
    }

}