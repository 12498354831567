@import "../../../_variables.scss";

.antigram-panel-root {
    padding-bottom: 5rem;
}

.test-table-antigram-panel-header-wrapper-view {
    // background: $color-white;
    // position: sticky;
    // position: -webkit-sticky;
    // top: 0px;
    // z-index: 999;

    .antigram-panel-header {
        .antigram-title-row {
            display: flex;
            align-items: center;
            padding: 0.385rem 1.5rem 0.448rem 1.5rem;
            border-bottom: 0.0625rem solid $color-gray;
            box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
            margin-bottom: 1.5rem;

            .workuptitle {
                font-weight: 500;
                font-size: 2.1875rem;
                line-height: 2.5625rem;
                letter-spacing: 0.25px;
                color: $color-secondary;
                margin: 0;
                // display: inline-block;
                max-width: 40%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .workuptitleMain {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }


            .last-modified {
                color: $color-black-light;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.3125rem;
                margin-left: 1rem;
            }

            .view-only {
                font-weight: 500;
                font-size: 0.875rem;
                color: $color-black-light;
            }

            button {
                padding: 0.8125rem 2rem;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.125rem;
                border-radius: 0.3125rem;
                text-transform: capitalize;
            }

            .error-icon {
                margin-left: 0.5rem;
            }

            .wk-submit-btn {
                background: $color-primary;
                margin-left: 1.5rem;
                border: none;
                box-shadow: none;
            }

            .download-button {
                margin-left: auto;
                padding: 0.5rem;
                color: $color-primary;
                border: 0.16rem solid $color-primary;
                border-radius: 0.3125rem;
                font-size: x-large;
                cursor: pointer;
            }
            
            .hyphen {
                margin-left: 0.75rem;
                margin-right: 0.75rem;
            }

        }

    }
}

.view-only-hover-popUp {
    .MuiTooltip-arrow {
        color: $color-white;
    }
}

@media print {
    #head {
        display: none;
    }

    #pdf {
        max-width: 100%;
    }
}