.spinner-root {
    display: flex;
    vertical-align: middle;
    align-items: center;
    position: absolute;
    justify-content: center;
    z-index: 999;
    height:100vh;
    width: 100%;
    .spinner-container {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        display: flex;
        z-index: 1;
        justify-content: center;
        background-color: #34383C;
        opacity: 0.3;
    }

    .spinner {
        position: fixed;
        animation: rotation 8s infinite linear;
        width: 4rem;
        height: 4rem;
        z-index: 9;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}