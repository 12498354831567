@import "../../../variables.scss";

.sample-id-box {
  background-color: $color-white;
  max-width: 34.5rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .rti--container {
    max-width: 18.75rem;
    display: contents;
    .rti--tag {
      background: $color-white;
      border-radius: 2rem;
      margin: 0.4rem 0 0.4rem 1rem;
      padding: 0.5rem;
      border: 0.063rem solid $color-secondary;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375;
      color:$color-secondary;
    }
  }
  .error-ic {
    vertical-align: middle;
    font-size: 1.25rem;
  }

  .sample-id-footer {
    font-size: 1rem;
    font-weight: 600;
    padding: 1.563rem 0;
    border-top: 0.063rem solid $color-medium-gray;
    line-height: 1.5rem;
    text-align: center;
    text-align: center;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    .sample-done-btn {
      background-color: $color-primary;
      color: $color-white;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 500;
      padding: 0.625rem 2rem;
      text-transform: capitalize;

      &:hover {
        background-color: $color-primary;
      }
    }
  }

  .sample-id-heading {
    font-size: 1rem;
    font-weight: 600;
    padding: 1.563rem 1rem;
    border-bottom: 0.063rem solid $color-medium-gray;
    line-height: 1.5rem;
    color: $color-secondary;
    .close-button {
      float: right;
      margin-top: 0.125rem;
      cursor: pointer;
    }
  }

  .sample-data {
    padding: 1.5rem;
    border-bottom: 0.063rem solid $color-gray;
    font-weight: 400;

    .error-icon {
      color: $color-secondary;
    }

    .sample-test-results-data {
      margin-bottom: 3.125rem;
      font-size: 0.875rem;
      font-weight: 400;
      color: $color-black;
      margin-left: 0.313rem;
      line-height: 1.313rem;
    }
  }

  .sample-input-box {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
    flex: 1 1;
    .input-heading {
      margin-bottom: 1rem;
      font-weight: 400;
      font-size: 1.125rem;
      letter-spacing: 0.009rem;
      line-height: 1.375rem;
      color:$color-black;
    }

    .input-text {
      background-color: $color-light-gray;
      display: inline-block;
      resize: none;
      width: intrinsic;
      width: -moz-max-content;
      width: -webkit-max-content;
      width: -webkit-fill-available;
      min-height: 6.25rem;
      border-radius: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-lightest-grey;
      border: 0;
      margin-bottom: 1rem;
      line-height: 1.375rem;
      padding: 0.938rem 0rem 0.938rem 0.625rem;
      font-family: "Rubik", sans-serif;
      &:focus-visible {
        border: 0;
        outline: none;
      }
    }

    .input-note {
      font-weight: 500;
      font-size: 0.875rem;
      line-height:1.125rem;
      color:$color-lightest-grey;
    }
  }

  .sample-id-btn {
    background-color: $color-primary;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    border-radius: 0.313rem;
    text-transform: none;
    min-width: 26.2rem;
    margin-top: 1rem;

    &:hover {
      background-color: $color-primary;
    }
  }

  .sample-id-btn-disable {
    background-color: $color-primary;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    border-radius: 0.313rem;
    text-transform: none;
    min-width: 26.2rem;
    margin-top: 1rem;

    &:hover {
      background-color: $color-primary;
    }

    &:disabled {
      opacity: 0.5;
      color: $color-white;
    }
  }
}
.sample-unlink-btn {
  color: $color-primary;
  text-transform: capitalize;
}
.sample-id-footer {
  text-align: center;

  .sample-done-btn {
    background-color: $color-primary;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    text-transform: capitalize;
    &:hover {
      background-color: $color-primary;
    }
  }
}
.deletesampleId-success-message {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 1rem 1.313rem 1rem 1.125rem;
  align-items: flex-start;
  gap: 0.625rem;
  position: fixed;
  width: 24.5rem;
  height: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 5.25rem;
  background: $color-sucess-secondary-light;
  border: 0.063rem solid $color-sucess-secondary;
  box-shadow: 0rem 0.5rem 1rem $color-black-medium;
  border-radius: 0.25rem;
  z-index: 999;
}
.drawer-sample-id {
  background-color: $color-white;
  max-width: 29.1rem;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .error-ic {
    vertical-align: middle;
    font-size: 1.25rem;
  }
  .rti--container {
    display: block;
    border: none;
    flex: 1 1;
    overflow-y: scroll;
    .rti--tag {
      background: $color-white;
      border-radius: 2rem;
      margin: 0.4rem 0 0.4rem 0.4rem;
      padding: 0.5rem;
      border: 0.063rem solid $color-secondary;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375;
      color:$color-secondary;
    }
  }
  .sample-id-footer {
    font-size: 1rem;
    font-weight: 600;
    padding: 1.563rem 0;
    border-top: 0.063rem solid $color-medium-gray;
    line-height: 1.5rem;
    text-align: center;
    text-align: center;
    width: 100%;
    bottom: 0;
    .sample-done-btn {
      background-color: $color-primary;
      color: $color-white;
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-weight: 500;
      padding: 0.625rem 2rem;
      text-transform: capitalize;
      &:hover {
        background-color: $color-primary;
      }
    }
  }
  .sample-id-heading {
    font-size: 1rem;
    font-weight: 600;
    padding: 1.563rem 1rem 0.5rem 1rem;
    border-bottom: 0.063rem solid $color-medium-gray;
    line-height: 1.5rem;
    color: $color-secondary;
    .close-button {
      float: right;
      margin-top: 0.125rem;
      cursor: pointer;
    }
  }
  .sample-data {
    padding: 1.5rem;
    border-bottom: 0.063rem solid $color-gray;
    font-weight: 400;
    .error-icon {
      color: $color-secondary;
    }

    .sample-test-results-data {
      margin-bottom: 3.125rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-black;
      margin-left: 0.313rem;
      line-height: 1.313rem;
    }
  }
  .sample-input-box {
    padding: 1rem 1rem 0rem 1rem;
    .input-heading {
      margin-bottom: 1rem;
      line-height: 1.375rem;
      font-weight: 400;
      font-size: 1.125rem;
      letter-spacing: 0.009rem;
      color:$color-black;
    }
    .input-text {
      background-color: $color-light-gray;
      display: inline-block;
      resize: none;
      width: intrinsic;
      width: -moz-max-content;
      width: -webkit-max-content;
      width: -webkit-fill-available;
      min-height: 6.25rem;
      border-radius: 0.5rem;
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-black;
      border: 0;
      margin-bottom: 1rem;
      padding: 0.938rem 0rem 0.938rem 0.625rem;
      line-height: 1.375rem;
      &:focus-visible {
        border: 0;
        outline: none;
      }
    }
    .input-note {
      font-weight: 500;
      font-size: 0.875rem;
      line-height:1.125rem;
      color:$color-lightest-grey;
    }
  }
  .sample-id-btn {
    background-color: $color-primary;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    border-radius: 0.313rem;
    text-transform: none;
    min-width: 26.2rem;
    margin-top: 1rem;
    &:hover {
      background-color: $color-primary;
    }
  }
  .sample-id-btn-disable {
    background-color: $color-primary;
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    border-radius: 0.313rem;
    text-transform: none;
    min-width: 26.2rem;
    margin-top: 1rem;
    &:hover {
      background-color: $color-primary;
    }
    &:disabled {
      opacity: 0.5;
      color: white;
    }
  }
}