@import "../../_variables.scss";

.test-table-root {
    padding: 0 1.5rem;
    
    .MuiBox-root {

        .table-container {

            table.table {
                border: 0.0625rem solid $color-light-gray;
                border-radius: 1rem;

                tr {
                    th:last-child{
                        width: 1rem;
                    }
                    th.MuiTableCell-root {
                        padding: 0.556rem 0.75rem;
                        font-weight: 600;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        color: $color-secondary;
                        background: $color-light-gray;
                        border-bottom: 0.0625rem solid $color-medium-gray;
                        
                        .table-col-heading-wrapeer {
                            display: flex;
                            align-items: center;
                        }

                        .table-sorting-icons {
                            display: inline-grid;
                            float: right;
                            margin-left: 0.25rem;
                            cursor: pointer;

                            :first-child {
                                margin-bottom: 0.25rem;
                            }
                        }

                        .red {
                            path {
                                fill: $color-primary;
                            }
                        }
                    }

                    &.selected {
                        background-color: $color-blue-gray;
                    }

                    &.highlighted{
                        background-color: $color-blue-gray;
                    }

                    td.MuiTableCell-root {
                        padding: 0.156rem 0.75rem;
                        font-weight: 400;
                        font-size: 0.875rem;
                        line-height: 1.313rem;
                        max-width: 6rem;
                        color: $color-secondary;
                        border-bottom: 0.0625rem solid $color-medium-gray;
                        .sample-id-workup-container{
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }

                    .user-workupname-container {
                        color: $color-primary;
                        text-decoration-color: $color-primary;
                        font-weight: 500;
                        max-width: 6rem;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: inline-block;
                    }
                }

                .status{ 
                    span{
                    font-weight: 500;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    letter-spacing: 0.02rem;
                    text-transform: uppercase;
                    padding: 0.25rem 0.5rem;
                    border-radius: 62.438rem;
                }
                .new{
                    background-color: $color-info-light;
                }
                .in-progress{
                    background-color: $color-warning;
                }
                .in-review{
                    background-color: $color-rating;
                }
                .completed{
                    background-color: $color-success;
                }
            }
                #add-button {
                    border: 0.063rem solid $color-primary;
                    color: $color-primary;
                    border-radius: 0.313rem;
                    padding: 0.25rem 0.5rem;
                    font-size: 0.75rem;
                    font-weight: 500;
                }
            }
        }
    }
}

.more-icon {
    color: $color-secondary;
}

.no-result-found {
    padding: 0rem 2.5rem;
    text-align: center;

    .circle {
        justify-content: center;
        margin: 0rem auto;
        background-color: $color-primary-light;
        border: 0.0625rem solid $color-gray;
        height: 14.563rem;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 14.563rem;
        display: flex;
        align-items: center;

    }
}
.create-workup-page{
    .filter-root {
    .search {
        .MuiInputBase-root-MuiOutlinedInput-root {
            border-radius: 3.688rem;
        }
    }
    }   
    .add-test-tilte{
        margin: 1rem auto 0.6rem 1.5rem;
        display: block;
        font-weight: 500;
        font-size: 1rem;
    }
}


.right-sec {
    display: flex;
    margin-left: auto;

    .filter-right-sec {
        margin-right: 0.625rem;

        .MuiButtonBase-root {
            background-color: $color-white;
            color: $color-primary;
            border: 0.063rem solid $color-primary;
            &:hover{
                background-color: $color-primary-light;
            }
        }
    }

    .sample-btn {
        background-color: $color-primary;
        color: $color-white;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 500;
        padding: 0.625rem 2rem;
        border-radius: 0.313rem;
        text-transform: none;
        border: none;
        &:hover{
            background-color: $color-primary-dark;
            border: none;
          }
    }
}

@media screen and (max-width:1280px){
    .test-table-root {
        .MuiBox-root {
            .table-container {
                table.table {
                    tr {
                        td.MuiTableCell-root {
                            max-width: 10rem;
                        }
                        .user-workupname-container {
                            display: inline-table;
                        }
                    }
                }
            }
        }
    }
}
