@import "../../../../variables.scss";
.classification-tab{
  padding: 1.375rem 0rem 1rem 0;
  background-color: $color-light-gray;
  .label-row{
    display: flex;

    .label{
      display: flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $color-secondary-dark;
      margin-right: 50px;
      padding: 0 0 1.336rem;

      span{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 8px;
        display: inline-block;
      }
      
    }
    .ruled-in{
      span{
        background: $color-black-light;
      }
    }
    .ruled-out{
      span{
        background: $color-disabled-light;
      }
    }
    .selected-rule{
      margin-left: auto;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      .rule{
        background-color: $color-secondary-dark;
        border-radius: 0.25rem;
        font-weight: 700;
        color: $color-white;
        min-width: 2.25rem;
        text-align: center;
        margin-left: 0.5rem;
        padding: 0.125rem 0.25rem;
      }
    }
  }
  .values-row{
    display: flex;
    .classfic-wrapper{
      flex: 1;

      &.changed{
        border: 2px solid $color-secondary-gold;
      }

      .classfic-cell{
        font-weight: 500;
        font-size: 20px;
        line-height: 23.7px;
        text-align: center;
        color: $color-secondary;
        background: $color-white;
        margin-right: 2px;
        border-radius: 8px;
        padding: 20px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .classific-ruled-in-bg{
        color: $color-white;
        background: $color-black-light;

        &.discrepent-cell{
          box-shadow: inset 0rem 0rem 0rem 0.125rem $color-secondary-gold;
          position: relative;

          &::before{
            content:"\A";
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background: $color-secondary-gold;
            display: inline-block;
            position: absolute;
            top: -1.125rem;
          }
        }
      }
      .classific-ruled-out-bg{
        color: $color-secondary;
        background:$color-disabled-light;
      }
      
      .count{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255, 255, 255, 0.96);
        background: $color-black-light;
        opacity: 0.94;
        border-radius: 8px;
        padding: 4px;
      }
    }
  }
}
.discrepant-hover-popup.MuiTooltip-popper{
  max-width: 15.688rem;
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
    padding: 0.625rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $color-black;
    background: $color-white;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
    .MuiTooltip-arrow{
      color: $color-white;
    }
  }
}
@media screen and (max-width:1200px){
  .classification-tab{
    .values-row{
      
    }
  }
}