@import "../../../_variables.scss";


.notification-container {
    display: flex;
    margin-top: 3rem;
    margin-left: auto;

    .notification-spinner-root {
        position: absolute;
        top: 50%;
        left: calc(50% - 00.625rem);

        .spinner {
            position: absolute;
            animation: rotation 8s infinite linear;
            width: 2rem;
            height: 2rem;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }
    }

    .MuiBox-root {
        padding: 2.188rem 1.563rem 2.188rem 1.375rem;
        width: 30%;
        border: none;
        height: 96%;

        .notification-label {
            display: flex;
            margin-bottom: 2.5rem;

            .notification-data {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.5rem;
                color: $color-secondary;
            }

            .movert-icon {
                margin-left: auto;
            }


        }

        .notification-buttons {
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;

            .button {
                padding: 0.813rem 2rem;
                border-radius: 0.313rem;
                border: 0.063rem solid $color-alt-ternary-dark;
                background: $color-alt-ternary-dark;
                color: $color-white;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.313rem;
                margin-right: 1.188rem;
                text-transform: none;
            }

            .inactive {
                background-color: $color-light-gray;
                color: $color-secondary;
                border: none;
                font-weight: 500;

                &:hover {
                    background-color: $color-blue-gray;
                }
            }

            .filter-component {
                margin-left: auto;

                .notification-filter {
                    border: 0.063rem solid $color-primary;
                    color: $color-primary;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    font-weight: 500;
                    padding: 0.813rem 2rem;
                    border-radius: 0.312rem;
                    text-transform: none;
                    cursor: pointer;
                    &:hover{
                        background-color: $color-primary-light;
                    }
                    .notification-count-filter {
                        font-weight: 500;
                        font-size: 0.625rem;
                        line-height: 0.938rem;
                        color: $color-white;
                        margin-right: 0.313rem;
                        background-color: $color-primary;
                        min-width: 0.938rem;
                        border-radius: 50%;
                    }
                }

            }
        }

        .notification-items {
            border: 0.063rem solid $color-gray;
            height: Calc(90vh - 14.5rem);
            overflow-y: scroll;
            position: relative;

            &::-webkit-scrollbar {
                width: 0.375rem;
            }

            &::-webkit-scrollbar-track {
                background: $color-lightest-white;
                border-width: 0rem 0.063rem;
                border-style: solid;
                border-color: $color-ligtest-white-grey;
                margin: 0.125rem;
            }

            &::-webkit-scrollbar-thumb {
                background: $color-secondary-dark-grey;
                border-radius: 6.25rem;

            }

            .notification-results-wrapper {
                border-bottom: 0.063rem solid $color-gray;
                padding-left: 1.5rem;

                &:hover {
                    background-color: $color-blue-gray;
                }

                .new-test-results {
                    padding-top: 1.25rem;
                    padding-right: 1.75rem;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    font-weight: 400;
                    color: $color-secondary;

                    strong {
                        font-weight: 500;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        color: $color-secondary;
                    }

                    p {
                        font-weight: 400;
                        font-size: 0.75rem;
                        line-height: 1.125rem;
                        color: $color-secondary;
                    }

                    .first-row-container {
                        display: flex;
                        cursor: pointer;

                        .actual-content {
                            word-break: break-all;
                        }

                        .second-column {
                            margin-top: 1.563rem;
                            margin-left: 1.563rem;
                        }
                    }
                }
            }

            .no-notifications {
                width: 100%;
                text-align: center;
                display: flex;
                height: Calc(85vh - 12.5rem);
                scroll-behavior: unset;
                color: $color-secondary;
                font-weight: 400;
                font-size: 1rem;

                p {
                    display: inline-block;
                    margin: 13.125rem auto auto auto;
                    align-self: center;
                    padding: 0 2rem;
                }
            }
        }

        .all-read-wrapper {
            margin-top: 1.25rem;
            display: flex;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.5rem;
            text-decoration-line: underline;
            color: $color-primary;
            cursor: pointer;

            .view-all {
                margin-left: auto;
            }

            .disabled {
                opacity: 0.5;
            }
        }

    }
}

.MuiButtonBase-root {
    border: 0.063rem solid $color-primary;
    color: $color-primary;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 500;
    padding: 0.625rem 2rem;
    border-radius: 0.312rem;
    text-transform: none;
    cursor: pointer;
}

.MuiCalendarPicker-root {
    .MuiSvgIcon-root {
        color: $color-secondary;
        font-weight: 500;
    }

    .MuiTypography-root {
        color: $color-secondary;
        font-size: 0.875rem;
        font-weight: 500;
    }

    .MuiPickersDay-root {
        color: $color-secondary;
        font-weight: 400;
    }

    .MuiPickersDay-root.Mui-selected {
        background-color: $color-primary;
        color: $color-white;
    }

    .MuiButtonBase-root.MuiPickersDay-today {
        background-color: $color-white ;
        color: $color-primary;
        border: 0.063rem solid $color-primary ;

        &:hover {
            background-color: $color-white;
        }
    }
}

.filter-modal-box {
    background-color: $color-white;
    box-shadow: 1.5rem;
    border-radius: 1rem;
    padding: 1.5rem;

    .filter-wrapper {
        display: flex;
        padding-bottom: 1.25rem;
        border-bottom: 0.063rem solid $color-gray;

        span {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-secondary;
        }

        Button {
            background: none;
            border: none;
            margin: 0;
            padding: 0;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-secondary;
            cursor: pointer;

        }

        .clear-label {
            margin-left: auto;
            font-weight: 400;
        }

        .disable-clear-label {
            margin-left: auto;
            font-weight: 400;
            color: $color-primary-grey;
        }

        .apply-filter-lable {
            margin-left: 1.5rem;
            color: $color-primary;
        }

        .disable-filter-lable {
            color: $color-red-disabled;
            margin-left: 1.5rem;
        }
    }

}

.notification-filter-date-wrapper {
    margin-top: 2rem;

    .MuiFormControl-root {
        width: 50%;
        background-color: $color-light-gray;
    }

    .MuiSvgIcon-root {
        color: $color-primary;
    }

    .date-label {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.313rem;
        margin-bottom: 1rem;
        color: $color-secondary;
    }

    label {
        font-size: 0.875rem;
    }

    .date-picker-label {

        display: flex;

        .MuiFormControl-root:first-child {
            margin-right: 1rem;
        }

        .MuiInputLabel-formControl,
        .MuiOutlinedInput-root {
            font-size: 0.875rem;
            color: $color-lightest-grey;
            line-height: 1.375rem;
        }

        .filter-date-wrapper {
            background: $color-light-gray;
            padding: 1rem;
            border: 0rem;
            border-radius: 0.313rem;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.375rem;
        }
    }

    .date-error-label {
        color: $color-primary;
    }
}

.notification-filter-panel-wrapper {
    margin-top: 2rem;

    .panel-label {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.313rem;
        color: $color-secondary;
        margin-bottom: 1.25rem;
    }

    .panel-container {
        display: block;

        .Mui-checked .MuiSvgIcon-root {
            fill: $color-primary;
        }

        .panel-types-items {
            width: 100%;

            .MuiTypography-root {
                color: $color-black-medium;
                font-weight: 400;
                font-size: 1rem;
                line-height: 150%;
            }
        }

        .MuiSvgIcon-root {
            color: $color-secondary;
        }
    }
}