@import "../../_variables.scss";

.mobile-device-banner {
    text-align: center;
    display: flex;
    height: 100vh;

    .banner-wrapper {
        display: inline-block;
        margin: 0 auto;
        align-self: center;
        padding: 0 2rem;
        .circle {
            justify-content: center;
            background-color: $color-primary-light;
            border: 0.0625rem solid $color-gray;
            height: 14.563rem;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            width: 14.563rem;
            display: flex;
            align-items: center;
            margin: 0 auto;
        }
        .herderfont {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 1.813rem;
            letter-spacing: 0.016rem;
            color: $color-secondary;
            padding: 2.063rem 0rem 1rem 0rem;
        }
        .bodyfont {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.375rem;
            letter-spacing: 0.009rem;
            color:$color-darker-gray
        }
    }
}