@import "../../../_variables.scss";

.table-wrapper {
  padding: 0rem 1.5rem;
  table{
    border-spacing: 0;
    //border-collapse: collapse;
    width: 100%;
    
    td, th{
      color: $color-secondary;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      font-weight: 400;
      text-align: center;
      padding: 0.3rem;
      border: 0.0625rem solid $color-gray;

   
    }
    td.dropdwon-open{
      cursor: pointer;
      position: relative;
      z-index: 1301;
      background: $color-white;
    }
    td.highlight-col, td.highlight-row{
      color: $color-white;
      background-color: $color-secondary;
    }

    .ovl-x{
      position: relative;

      &::before{
        content: url("../../../assets/images/Ovl-x.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        z-index: 111;
      }
    }
    .ovl-x.highlight-col, .ovl-x.highlight-row{
      &::before{
        content: url("../../../assets/images/Ovl-x-light.svg");
      }
    }

    .ovl-slash{
      position: relative;

      &::before{
        content: url("../../../assets/images/Ovl-slash.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        z-index: 111;
      }
    }
    .ovl-slash.highlight-col, .ovl-slash.highlight-row{
      &::before{
        content: url("../../../assets/images/Ovl-slash-light.svg");
      }
    }

    .ovl-ellipse{
      position: relative;

      &::before{
        content: url("../../../assets/images/Ovl-ellipse.svg");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -40%);
        z-index: 111;
      }
    }
    .ovl-ellipse.highlight-col, .ovl-ellipse.highlight-row{
      &::before{
        content: url("../../../assets/images/Ovl-ellipse-light.svg");
      }
    }

    .antigram-header-sticky{
      background: $color-white;
      position: sticky;
      position: -webkit-sticky;
      top: 0rem;
      z-index: 221;
      border-radius: 1rem;
      .table-group-heading{
        border-radius: 1rem 0rem 0rem 0rem;

        td{
          padding: 0rem;
          height: 2.5rem;
          box-sizing: border-box;

          &:first-child{
            color: $color-secondary;
            .undo-redo-container{
              border-radius: 1rem 0rem 0rem 0rem;
              display: flex;
              padding: 0.469rem 0.938rem;
              opacity: 0.5;
              background: $color-primary-blueGrey;
              .undo-redo-wrapper{
                display: flex;
                align-items: center;
                button{
                  align-items: center;
                  display: flex;
                  border: none;
                  border-radius: 1.09rem;
                  padding: 0.313rem;
                  .undoredo-icon{
                    font-size: medium;
                    color:$color-secondary;
                  }
                }
                .redo-button{
                  margin-left: 0.5rem;
                  margin-right: 1rem;
                }
              }
              .clear-button{
                margin-left: auto;
                .table-clear-button{
                  padding: 0.281rem 1rem;
                  border: none;
                  border-radius: 0.313rem;
                  line-height: 1.125rem;
                }
              }
            }
            .undo-redo-container-active{
              border-radius: 1rem 0rem 0rem 0rem;
              display: flex;
              padding: 0.469rem 0.938rem;
              background: $color-primary-blueGrey;
              .undo-redo-wrapper{
                display: flex;
                align-items: center;
                button{
                  align-items: center;
                  display: flex;
                  border: none;
                  border-radius: 1.09rem;
                  padding: 0.313rem;
                  cursor: pointer;
                  .undoredo-icon{
                    font-size: medium;
                    fill: $color-secondary;
                  }
                }
                .redo-button{
                  margin-left: 0.5rem;
                  margin-right: 1rem;
                  cursor: pointer;
                }
              }
              .clear-button{
                margin-left: auto;
                .table-clear-button{
                  padding: 0.281rem 1rem;
                  border: none;
                  border-radius: 0.313rem;
                  line-height: 1.125rem;
                  cursor: pointer;
                  color:$color-secondary;
                  font-weight: 500;
                  font-size: 0.875rem
                }
              }
            }
            .undo-redo-container.active{
              opacity: 1;
            }
          }
          &:not(:first-child){
            color: $color-secondary;
            background: $color-white;
          }
          &:last-child{
            border-radius: 0rem 1rem 0rem 0rem;
          }

          &:nth-child(6){
            font-weight: 400;
            font-size: 0.5rem;
            line-height: 0.6875rem;
          }
        }
      }

      .cell-row-header{
        background: $color-medium-gray;
  
        .D, .C, .E, .c, .e, .f, .Cw, .V, .K, .k, .Kpa, .Kpb, .Jsa, .Jsb, .Fya, .Fyb, .Jka, .Jkb, .Xga, .Lea, .Leb, .S, .s, .M, .N, .P1, .Lua, .Lub{
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        .Spc{
          font-size: 0.75rem;
          min-width: 5rem;
        }

        td{
          padding: 0rem;
          width: 2rem;
          height: 2.875rem;
          box-sizing: border-box;
  
          &:first-child{
            border-radius: 0rem 0rem 0rem 0rem;
          }

          .headerDropdown{
            display: none;
          }

          &:hover{
            .headerDropdown{
              display: flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              width: 100%;
              cursor: pointer;
              padding: 0.4rem 0;
            }
          }
        }
        td.dropdwon-open{
          .headerDropdown{
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            width: 100%;
            cursor: pointer;
            padding: 0.4rem 0;
          }
        }
  
        .ruled-out-bg{
          background-image: url("../../../assets/images/Bg-x.png");
          background-repeat: no-repeat;
          background-size: 108% 100%;
        }
        .ruled-out-bg.highlight-col{
          background-image: url("../../../assets/images/Bg-x-light.png");
        }

        .ruled-in-bg{
          background-image: url("../../../assets/images/Bg-ellipse.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
        .ruled-in-bg.highlight-col{
          background-image: url("../../../assets/images/Bg-ellipse-light.png");
        }
        
        .ruled-out-bg-slash{
          background-image: url("../../../assets/images/Bg-slash.png");
          background-repeat: no-repeat;
          background-size: 108% 100%;
        }
        .ruled-out-bg-slash.highlight-col{
          background-image: url("../../../assets/images/Bg-slash-light.png");
        }
      }
    }
    
    .panel-a-detail-row{
      padding: 0.175rem 0;

      .expand-icon{
        margin: 0 1.5rem 0 0.5rem;
        svg{
          fill: $color-primary;
        }
      }
      .panel-a-row{
        display: flex;
        align-items: center;

        span{
          font-size: 0.75rem;
          line-height: 1rem;
          color: $color-secondary-dark;

          &.panel-name, &.lot-no, &.sample-date{
            margin-right: 1rem;
          }
          b{
            font-weight: 700;
          }
        }

        .panel-name{
          font-weight: 700;
        }

        .panel-note-label{
          color: $color-primary;
          font-size: 0.875rem;
          line-height: 1.125rem;
          font-weight: 500;
          margin-left: auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 1.25rem;
          cursor: pointer;

          .notes-count{
            font-size: 0.625rem;
            line-height: 0.9375rem;
            font-weight: 500;
            background: $color-primary;
            border-radius: 0.5rem;
            color: $color-white;
            text-align: center;
            min-height: 1rem;
            min-width: 1rem;
            margin-left: 0.5rem;
            padding: 0.2rem 0.3rem;
          }
        }
        .see-details-label{
          color: $color-primary;
          font-size: 0.875rem;
          line-height: 1.125rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-right: 2.156rem;

          svg{
            margin-left: 1rem;
          }
        }
      }
    }

    .tr-col{
      font-style: normal;
      font-weight: 400;
      font-size: 0.813rem;
      line-height: 99.6%;
      text-transform: uppercase;
    }

    .iat, .fic, .fifth_column.true, .analyser{
      background: $color-medium-gray;
    }

    .disabled-cell {
        background: $color-gray;
    }

    .tr-col, .tst-columns{
      width: 1.2rem;
      max-width: 1.2rem;
      &.fifth_column{
        word-break: break-all;
        &.cl-title{
          cursor: pointer;
          color: $color-primary;
          white-space: nowrap;
        }
      }
    }

    .antigram-main-data{
      .cell_number{
        color: $color-white;
        background: $color-darker-gray;
      }
      .cell_number.highlight-row{
        color: $color-white;
        background-color: $color-secondary;
      }
      .Fya, .Fyb, .Xga, .S, .s, .M, .n{
        background: $color-medium-gray;
      }
      .highlight-col.Fya, .highlight-col.Fyb, .highlight-col.Xga, .highlight-col.S, .highlight-col.s, .highlight-col.M, .highlight-col.n,
      .highlight-row.Fya, .highlight-row.Fyb, .highlight-row.Xga, .highlight-row.S, .highlight-row.s, .highlight-row.M, .highlight-row.n{
        color: $color-white;
        background-color: $color-dark-gray;
      }
    }

    tr.table-group-footer{
      td{
        background: $color-white;
        height: 2.5rem;
        box-sizing: border-box;

        &:first-child {
          border-radius: 0rem 0rem 0rem 1rem;
        }
        &:last-child {
          border-radius: 0rem 0rem 1rem 0rem;
        }
      }
    }

  }
}
.disable-fifth-cell.MuiTooltip-popper{
  max-width: 15.625rem;
  .MuiTooltip-tooltip.MuiTooltip-tooltipArrow{
    padding: 0.675rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: $color-black;
    background: $color-white;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    .MuiTooltip-arrow{
      color: $color-white;
    }
  }
}
.autoControlPoppper{
  font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $color-black;
    background: $color-white;
    border-radius: 0.5rem;
    width: 14.5rem;
    padding: 0.625rem;
    z-index: 999;
    p{
        margin: 0;
    }
}

@media screen and (min-width: 1661px){
  .table-wrapper {
    padding: 0rem 15rem;
  }  
}
@media screen and (min-width: 1441px) and (max-width:1660px){
  .table-wrapper {
    padding: 0rem 5.5rem;
  }  
}
@media screen and (min-width: 1921px){
.table-wrapper {
    max-width: 87rem;
    margin: 0 auto;
}
}