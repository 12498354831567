@import "../../_variables.scss";

.profile-dropdown {
  .MuiPaper-root {
    border-radius: 1rem;
    box-shadow: 0rem 0.25rem 1.325rem rgba(0, 0, 0, 0.1);
    padding: 0rem 0.675rem;

    .MuiListItemText-root .MuiTypography-root {
      font-weight: 400;
      font-size: 0.875rem;
      color: $color-secondary;
      line-height: 1.325rem;

      a,
      a:link {
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
a {
  text-decoration: none;
}
