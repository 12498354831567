@import "../../variables.scss";
@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 3.125rem;
    height: 3.125rem;
    border: 0.188rem solid $color-white;
    border-top: 0.188rem solid  $color-black-light;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
    margin-top: 5rem;
    margin-left: 45%;
    margin-bottom: 5rem;
  }