@import "../../variables";

.snackbar-alert-message {
    position: fixed;
    z-index: 9999;

    .MuiSnackbar-root {
        top: 5rem;
        width: 100%;
        max-width: 27%;

        .alert-info-message {
            width: 100%;
            max-width: 100%;

            .MuiPaper-root {
                border-width: 0.063rem;
                border-style: solid;
                box-shadow: 0rem 0.5rem 1rem rgba(39, 40, 51, 0.2);
                border-radius: 0.25rem;
                padding: 1rem;

                .warning-icon {
                    margin-right: 0.313rem;
                    float: left;
                }

                .MuiAlert-message {
                    padding: 0;
                    display: flex;
                    overflow: initial;

                    div{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        .alert-note {
                            color: $color-secondary;
                            line-height: 150%;
                            font-size: 1rem;
                            font-weight: 400;
    
                            strong {
                                font-weight: 500;
                            }
                        }
                    }
                }

                .MuiAlert-action {
                    margin: 0;
                    padding: 0 0 0 1.313rem;

                    .close-icon {
                        padding: 0;
                        margin-right: 0.313rem;
                        color: $color-secondary;
                    }
                }
            }
        }
    }
}