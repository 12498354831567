@import "../../../../variables.scss";

.dat-result-container {
    padding: 1.9691rem 0;
    display: flex;
    background-color: $color-light-gray;

    .container-wrapper {
        display: flex;
        flex-direction: column;
        flex-basis: 25%; 
        flex-grow: 0;
        max-width: 16.25rem;

        .container-labels {
            margin-bottom: 0.375rem;

            span {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.313rem;
                color: $color-secondary;

            }
        }
        .dat-typing-card {
            display: flex;
            margin-right: 1rem;
            padding: 0.75rem;
            border: 0.063rem solid $color-white;
            border-radius: 0.313rem;
            background: $color-white;
            line-height: 1.313rem;
            &.disabled{
                background-color: $color-gray;
                border: $color-gray;
            }
            .not-tested-placeholder{
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.313rem;
                color: $color-secondary
            }
            .mixed-field {
                display: inline-flex;
                padding: 0.063rem 1rem;
                border-radius: 0.5rem;
                background: $color-primary;
                color: $color-white;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.375rem;
                margin-left: 0.625rem;
            }
            .arrow-down-button {
                display: flex;
                margin-left: auto;               
            }

            .arrow-disabled *{
                fill:$color-primary-grey;
            }
        }

    }

}

.dat-dropdown-menu {
    margin-top: 0.125rem;

    .MuiPaper-root {
        ul.MuiList-root {
            background: $color-white;
            border: 0.063rem solid $color-medium-gray;
            border-radius: 0.25rem;
            padding: 1rem;
            min-width: 13rem;          
            overflow-y: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            .dat-dropdown-headers {
                font-weight: 600;
                line-height: 1.313rem;

            }

            .selected-menu{
                background-color: $color-light-gray;
            }

            li.MuiMenuItem-root {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.375rem;
                color: $color-secondary;
                padding-bottom:0.438rem;
                margin: 0;
                &:hover {
                    background: $color-light-gray;
                }
            }

            li.switch-btn{
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.875rem;
                text-align: center;
                color: $color-black;
                background: $color-white;
                border-top: 0.063rem solid $color-gray;
                padding: 0.313rem 0;
                position: sticky;
                bottom: 0;
                &:hover{
                    background: $color-white;
                }

             
                .MuiSwitch-root{
                    margin: -0.438rem;
                    padding-right: 0.725rem;
                    width: 3.3rem;
                    height: 1.85rem;

                    .MuiSwitch-switchBase.Mui-checked {
                        -webkit-transform: translateX(1rem);
                        -moz-transform: translateX(1rem);
                        -ms-transform: translateX(1rem);
                        transform: translateX(1rem);

                        .MuiSwitch-thumb {
                            color: $color-primary;
                            border: 0.063rem solid transparent;
                        }
                    }
                    
                    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
                        background: $color-primary;
                        opacity: 0.5;
                    }

                    .MuiSwitch-switchBase {
                        .MuiSwitch-thumb {
                            width: 1.187rem;
                            height: 1.187rem;
                            border: 0.063rem solid $color-gray;
                        }
                    }
                    
                    .MuiSwitch-track{
                        background: $color-gray;
                        opacity: 1;
                    }
                }
             
            }


        }
    }
}