@import "../../../_variables.scss";

.antigram-panel-header-wrapper {
  background: $color-white;
  position: sticky;
  position: -webkit-sticky;
  top: 0rem;
  z-index: 222;

.workup-created-message{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 1.063rem 2rem 0rem 1.188rem;
  align-items: flex-start;
  position: fixed;
  width: 24.5rem;
  height: 5rem;
  left: 0;
  right: 0;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
  top: 5.25rem;
  background: $color-sucess-secondary-light;
  border: 0.063rem solid $color-sucess-secondary;
  box-shadow: 0rem 0.5rem 1rem rgba(39, 40, 51, 0.2);
  border-radius: 0.25rem;
  z-index: 99;
  .main-content{
    margin-left: 0.375rem;
    .bold-content{
      font-weight: 500;
    }
    .normal-content{
      margin-left: 0.25rem;
    }
  }
}
  .antigram-panel-header {
    .antigram-title-row {
      display: flex;
      align-items: center;
      padding: 0.385rem 1.5rem 0.448rem 1.5rem;
      border-bottom: 0.063rem solid $color-gray;
      height: 2.625rem;
      box-shadow: 0 0.125rem 0.250rem rgba(0, 0, 0, 0.1);

      .workuptitle {
        font-weight: 500;
        font-size: 2.188rem;
        line-height: 2.563rem;
        letter-spacing: 0.016rem;
        color: $color-secondary;
        margin: 0;
        display: inline-block;
        max-width: 23.75rem;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    
      
      .edit-vector-icon{
        margin-left: 1rem;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
      }

      .status {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $color-secondary;
        background: $color-warning;
        border-radius: 62.438rem;
        margin-left: 1rem;
        padding: 0.25rem 0.5rem;
      }
      .completed-status {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $color-secondary;
        background: $color-success;
        border-radius: 62.438rem;
        margin-left: 1rem;
        padding: 0.25rem 0.5rem;
      }

      .last-modified {
        color: $color-dark-gray;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.313rem;
        margin-left: 1rem;
      }

      button {
        padding: 0.613rem 2rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
        border-radius: 0.313rem;
        text-transform: capitalize;
      }
      .wk-download-btn {
        margin-left: auto;
        padding: 0.4rem;
        color: $color-secondary;
        border: 0.063rem solid $color-secondary;
        border-radius: 0.3125rem;
        font-size: x-large;
        cursor: pointer;
        background-color: $color-white;
        &:hover{
          background-color:$color-light-gray;
        }
    }
      .wk-notes-btn {
        margin-left: 1.5rem;
        color: $color-secondary;
        border-color: $color-secondary;
        .notes-count {
          font-size: 0.625rem;
          line-height: 0.938rem;
          font-weight: 500;
          background: $color-secondary;
          border-radius: 0.5rem;
          color: $color-white;
          text-align: center;
          min-height: 1rem;
          min-width: 1rem;
          margin-left: 0.5rem;
          padding:0.07rem;
          text-align: center;
        }
      }

      .wk-save-btn {
        margin-left: 1.5rem;
        color: $color-secondary;
        border-color: $color-secondary;
      }

      .wk-submit-btn {
        margin-left: 1.5rem;
        background: $color-primary;
        color: $color-white;
        box-shadow: none;
        &:hover{
          background-color: $color-primary-dark;
          border: none;
        }
      }
      button:disabled{
        opacity: 0.5;
      }

    }

    .antigram-customization-row {
      background: $color-light-gray;

      .antigram-customization-toggle-wrapper {
        margin-bottom: 1rem;
        
        .cust-box-wrapper{
          .tab-row-wrapper {
            display: flex;
            align-items: center;
            background: $color-light-gray;
            border-bottom: 0.125rem solid $color-gray;

            .option-sec{
              display: flex;
              align-items: center;
              width: 36%;

              .toggle-btn{
                width: 100%;

                .MuiButtonGroup-root{
                  background: $color-light-gray;
                  border-right: 0.125rem solid $color-gray;
                  border-radius: 0;
                  width: 100%;

                  button{
                    font-weight: 500;
                    font-size: 0.875rem;
                    line-height: 1.125rem;
                    color: $color-secondary;
                    border: none;
                    padding: 0.938rem 4.688rem;
                    border-radius: 0;
                    width: 100%;
                  }
                  button.selected{
                    color: $color-white;
                    background: $color-alt-ternary-dark;
                  }
                  button.btn-rec{
                    svg{
                      margin-left: 0.625rem;
                    }
                    &.rec-discrepency{

                      &::after{
                        content:"\A";
                        width: 0.5rem;
                        min-width: 0.5rem;
                        height: 0.5rem;
                        border-radius: 50%;
                        background: $color-secondary-gold;
                        display: inline-block;
                        margin: -0.625rem 0 0 0.313rem;
                      }
                    }
                  }
                  button.btn-dropdown{
                    border-width: 0 0.125rem;
                    border-style: solid;
                    border-color: $color-gray;
                    padding: 0 0.313rem;
                    width: 9%;
                  }
                  .btn-dropdown.selected{
                    color: $color-white;
                    background: $color-alt-ternary-dark;

                  }
                }
              }

              .rules-btn{
                margin-left: 1.5rem;
                button{
                  font-weight: 500;
                  font-size: 0.875rem;
                  line-height: 1.375rem;
                  text-align: center;
                  letter-spacing: 0.009rem;
                  text-transform: uppercase;
                  color: $color-primary;
                  border: 0.063rem solid $color-primary;
                  border-radius: 3.125rem;
                  padding: 0.438rem 2.5rem;

                  .rule-badge{
                    background: $color-primary;
                    opacity: 0.94;
                    border-radius: 0.25rem;
                    padding: 0.125rem 0.25rem;
                    font-weight: 700;
                    font-size: 0.75rem;
                    line-height: 0.938rem;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.96);
                    margin: 0 0.375rem 0 0.5rem;
                    word-break: keep-all;
                    white-space: nowrap;
                  }
                }
              }
            }
  
            .tabs-group {
              flex: 1 1;
  
              .MuiTabs-flexContainer{
                button.clasification-tab{
                  // display: none;
                  height: 0;
                  width: 0;
                  margin: 0;
                  padding: 0;
                  opacity: 0;
                  min-width: 0;
                  max-width: 0.1px;
                  display: inline;
                  // position: absolute;
                  visibility: hidden;
                }
                button {
                  font-weight: 500;
                  font-size: 0.875rem;
                  line-height: 1.125rem;
                  text-align: center;
                  text-transform: capitalize;
                  color: $color-secondary;
                  padding: 0.563rem 1rem;
                  margin: 0.375rem 2.75rem;
                  min-height: auto;
                  flex: 1 1;
                  
                  &:hover{
                    background-color: $color-medium-gray;
                  }
                  svg{
                    color: $color-alt-ternary-dark;
                    border-radius: 50%;
                    margin: 0 0 0 0.563rem;
                    width: 1.125rem;
                    height: 1.125rem;
                  }
                }
                button.Mui-selected{
                  background: $color-gray;
                  border-radius: 0.25rem;
                  color: $color-secondary;
                }
              }
              .MuiTabs-indicator{
                display: none;
              }
            }

            .MuiAccordionSummary-root{
              padding: 0;
            }
          }
          .MuiAccordion-root{
            background: $color-light-gray;
            box-shadow: none;
            padding: 0 1.5rem;

            .MuiAccordionDetails-root{
              padding: 0;

              .MuiBox-root{
                padding: 0;
              }
              .antigenTyping-tab-wrapper{
                .snackbar-alert-message{
                  .MuiSnackbar-root{
                    .alert-info-message{
                      .MuiPaper-root{
                        background: $color-lighter-yellow;
                        border: 0.063rem solid $color-secondary-gold;
                        box-shadow: 0rem 0.5rem 1rem rgba(39, 40, 51, 0.2);
                        border-radius: 0.25rem;
                      }
                    }
                  }
                }
              }
              .autocontol-tab{
                padding: 0.8551rem 0;
              }
            }
          }
        }
      }

    }
  }
}

.rule-dropdown{
  .MuiPaper-root.MuiPopover-paper{
    margin-top: 0;
    background: $color-white;
    border: 0.063rem solid $color-alt-ternary-dark;
    border-radius: 0;
    min-width: 15.938rem;
    
    ul.MuiList-root{
      padding: 2.5rem 1.5rem;
      
      .dropdn-list{
        display: block;
        padding: 0.5rem 1.188rem 0.5rem 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $color-darker-gray;
        margin-bottom: 0.813rem;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .dropdn-list.active{
        color: $color-secondary;
        font-weight: 600;

        span{
          margin-left: auto;
        }
      }
      
      button{
        padding: 0.813rem 2rem;
        border-radius: 0.313rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.125rem;
        text-align: center;
        display: block;
        width: 100%;
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 1661px){
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters{
    padding: 0 15rem;
  }
}

@media screen and (min-width: 1441px) and (max-width:1660px){
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters{
    padding: 0 5.5rem;
  }
}
@media screen and (min-width: 1921px) {
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters{
    max-width: 87rem;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1200px) {
  .antigram-panel-header-wrapper{
    .antigram-panel-header {
      width: 100%;
      .antigram-title-row {
        height: auto;
      }
    }
  }
}
