@import "../../../_variables.scss";

.about-page-container {
    padding: 1.5rem 3.375rem 0rem 2.563rem;

    .about-page-wrapper {
        .about-page-heading {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 2.563rem;
            color: $color-black;
            letter-spacing: 0.016rem;
        }
    }

    .about-page-rule {
        margin-top: 2.188rem;

        .rule-label {
            font-weight: 400;
            font-size: 1.3rem;
            line-height: 1.5rem;
            color: $color-black;
        }

        .rule-paragraph {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.313rem;
            color: $color-secondary;
        }
    }
}