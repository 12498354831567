@import "../../../../_variables.scss";
.antigram-no-test-results {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-lightest-grey;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding:5rem 2.5rem
}
.test-table-wrapper {
  padding: 0rem 1.5rem;

  table {
    border-spacing: 0;
    //border-collapse: collapse;
    width: 100%;

    td,
    th {
      color: $color-secondary;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      font-weight: 400;
      text-align: center;
      padding: 0.3rem;
      border: 0.0625rem solid $color-gray;
    }

    .antigram-header-sticky {
      background: $color-white;
      position: sticky;
      position: -webkit-sticky;
      top: 0rem;
      z-index: 221;

      .table-group-heading {
        td {
          padding: 0rem;
          height: 2.5rem;
          box-sizing: border-box;

          &:first-child {
            border: none;
          }

          &:not(:first-child) {
            color: $color-secondary;
            background: $color-white;
          }

          &:nth-child(2) {
            border-radius: 1rem 0rem 0rem 0rem;
          }

          &:last-child {
            border-radius: 0rem 1rem 0rem 0rem;
          }

          &:nth-child(6) {
            font-weight: 400;
            font-size: 0.5rem;
            line-height: 0.6875rem;
          }
        }
      }

      .cell-row-header {
        background: $color-medium-gray;

        .D,
        .C,
        .E,
        .c,
        .e,
        .f,
        .Cw,
        .V,
        .K,
        .k,
        .Kpa,
        .Kpb,
        .Jsa,
        .Jsb,
        .Fya,
        .Fyb,
        .Jka,
        .Jkb,
        .Xga,
        .Lea,
        .Leb,
        .S,
        .s,
        .M,
        .N,
        .P1,
        .Lua,
        .Lub {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.5rem;
        }

        .Spc {
          font-size: 0.75rem;
          min-width: 5rem;
        }

        td {
          padding: 0rem;
          width: 2rem;
          height: 2.875rem;
          box-sizing: border-box;

          &:first-child {
            border-radius: 1rem 0rem 0rem 0rem;
          }
        }
      }
    }

    .panel-a-detail-row {
      padding: 0.50rem 0.9375rem;

      .panel-a-row {
        display: flex;
        align-items: center;

        span {
          font-size: 0.75rem;
          line-height: 1rem;
          color: $color-secondary-dark;
          text-overflow: ellipsis;
          max-width: 40%;
          overflow: hidden;
          white-space: nowrap;

          &:not(:last-child) {
            margin-right: 1rem;
          }

          b {
            font-weight: 700;
          }
        }

        .panel-name {
          font-weight: 700;
        }

        .see-details-label {
          color: $color-primary;
          font-size: 0.875rem;
          line-height: 1.125rem;
          font-weight: 500;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: auto;
        }
      }
    }

    .tr-col{
      font-style: normal;
      font-weight: 400;
      font-size: 0.813rem;
      line-height: 99.6%;
      text-transform: uppercase;
    }
    .iat, .fic, .user_entered.true{
      background: $color-medium-gray;
    }
    .tr-col, .tst-columns{
      width: 1.2rem;
      max-width: 1.2rem;
      &.user_entered{
        word-break: break-all;
        &.cl-title{
          cursor: pointer;
          color: $color-primary;
          white-space: nowrap;
        }
      }
    }
    .antigram-main-data {
      .cell_number {
        color: $color-white;
        background: $color-darker-gray;
      }

      .Fya,
      .Fyb,
      .Xga,
      .S,
      .s,
      .M,
      .n {
        background: $color-medium-gray;
      }
    }

    tr.table-group-footer {
      td {
        background: $color-white;
        height: 2.5rem;
        box-sizing: border-box;

        &:first-child {
          border-radius: 0rem 0rem 0rem 1rem;
        }

        &:last-child {
          border-radius: 0rem 0rem 1rem 0rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1661px){     
  .test-table-wrapper {
    padding: 0rem 15rem;
  }
}

@media screen and (min-width: 1441px) and (max-width:1660px){     
  .test-table-wrapper {
    padding: 0rem 5.5rem;
  }
}
@media screen and (min-width: 1921px) {
  .test-table-wrapper {
    max-width: 87rem;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1200px) {
  .test-table-wrapper {
    
  }
}