@import "../../../../variables.scss";


.antigen-typing-wrapper{
    display: flex;
    padding: 1.4765rem 0rem;
    background: $color-light-gray;

    .antigen-typing-cell-outer{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;

        .antigen-typing-cell{
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.5rem;
            text-align: center;
            color: $color-secondary;
            background: $color-white;
            border: 0.063rem solid $color-gray;
            border-radius: 0.5rem;
            padding: 1.25rem 0.25rem;
            margin-right: 0.125rem;
            margin-bottom: 0.25rem;
        }
        .antigen-typing-cell.mf-cell{
            position: relative;

            &.analyzer{
                background: $color-gray;
            }

            .mf-tag{
                font-weight: 600;
                font-size: 0.75rem;
                line-height: 1.125rem;
                display: flex;
                justify-content: center;
                text-align: center;
                color: $color-white;
                background: $color-primary;
                border-radius: 0.25rem;
                padding: 0rem 0.375rem;
                position: absolute;
                bottom: 0.188rem;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .antigen-typing-card{
            font-weight: 500;
            font-size: 0.75rem;
            line-height: 0.875rem;
            text-align: center;
            color: $color-black;
            background: $color-white;
            border: 0.063rem solid $color-gray;
            border-radius: 0.25rem;
            padding: 0.25rem;
            margin-right: 0.125rem;
            min-height: 0.80rem;
        }
    }

    .antigen-typing-cell-outer.disabled{
        .antigen-typing-cell,.antigen-typing-card{
            background: $color-gray;
        }
    }
}

.antigen-type-drodown-modal-main{
    .MuiPaper-root {
        ul.MuiList-root{
            background: $color-white;
            border: 0.063rem solid $color-gray;
            border-radius: 0.188rem;
            padding: 0.25rem 0.156rem;

            &::-webkit-scrollbar {
                display: none;
            }

            li.MuiMenuItem-root{
                font-weight: 500;
                font-size: 0.813rem;
                line-height: 1.375rem;
                color: $color-secondary;
                text-align: center;
                justify-content: center;
                padding: 0.375rem 2.813rem 0.375rem 0.75rem;
                margin: 0;

                &:hover, &.active{
                    background: $color-light-gray;
                }
            }
            li.switch-btn{
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.875rem;
                text-align: center;
                color: $color-black;
                background: $color-white;
                border-top: 0.063rem solid $color-gray;
                padding: 0.313rem 0;
                position: sticky;
                bottom: 0;
                &:hover{
                    background: $color-white;
                }
                .MuiSwitch-root{
                    margin: -0.438rem;
                    padding-right: 0.725rem;
                    width: 3.3rem;
                    height: 1.85rem;

                    .MuiSwitch-switchBase.Mui-checked {
                        -webkit-transform: translateX(1rem);
                        -moz-transform: translateX(1rem);
                        -ms-transform: translateX(1rem);
                        transform: translateX(1rem);

                        .MuiSwitch-thumb {
                            color: $color-primary;
                            border: 0.063rem solid transparent;
                        }
                    }
                    
                    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
                        background: $color-primary;
                        opacity: 0.5;
                    }

                    .MuiSwitch-switchBase {
                        .MuiSwitch-thumb {
                            width: 1.187rem;
                            height: 1.187rem;
                            border: 0.063rem solid $color-gray;
                        }
                    }
                    
                    .MuiSwitch-track{
                        background: $color-gray;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.antyTypCellPopper {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $color-black;
    background: $color-white;
    border-radius: 0.5rem;
    width: 14.5rem;
    padding: 0.625rem;
    z-index: 999;
    filter: drop-shadow(0rem 0.25rem 1.875rem rgba(0, 0, 0, 0.15));
    p{
        margin: 0;
    }
}