@import "../../_variables.scss";
.server-container{
.MuiToolbar-root {
  height: 3.25rem;
  min-height: 3.25rem;
  background-color: $color-primary;

.server-error-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem;
  gap: 1.875rem;
  overflow: hidden;
  margin-left: 10%;
  margin-top: 50%;
  .status-msg {
    font-weight: 700;
    font-size: 2.188rem;
    line-height: 2.563rem;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.016rem;
    color: $color-secondary;
  }
  .error-descr {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.813rem;
    text-align: center;
    letter-spacing: 0.016rem;
    color: $color-darker-gray;
    .login-span{
      display: flex;
      .login-error{
        padding:0 0.3rem 0 0.3rem;
        text-decoration: underline;
        &:hover{
          cursor:pointer;
        }
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.009rem;
    color: $color-darker-gray;
    span {
      color: $color-primary;
    }
  }
}
}
}
@media screen and (max-width:1200px){
  .server-container{
    .server-error-handler {
      transform: translate(0%, 65%);
      padding: 0;
      margin-top: 0%;
      margin-left: 0%;
    }
  }
}