@import "../../variables.scss";

.wk-notes-btn {
  margin-left: 1.5rem;
  color: $color-secondary;
  border-color: $color-secondary;

  .notes-count {
    font-size: 0.625rem;
    line-height: 0.938rem;
    font-weight: 500;
    background: $color-secondary;
    border-radius: 0.5rem;
    color: $color-white;
    text-align: center;
    min-height: 1rem;
    min-width: 1rem;
    margin-left: 0.5rem;
  }
}

.notes-flyout-box {
  padding-right: 0;
  overflow-y: scroll;

  div[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }

  .deletenote-success-message {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 1.063rem 0.663rem 0rem 1.063rem;
    align-items: flex-start;
    gap: 0.625rem;
    position: fixed;
    width: 30%;
    height: 5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 5.25rem;
    background: $color-sucess-secondary-light;
    border: 0.063rem solid $color-sucess-secondary;
    box-shadow: 0rem 0.5rem 1rem $color-black-medium;
    border-radius: 0.25rem;
    z-index: 999;
  }

  .workup-notes-pop {
    width: 36.375rem;
    background-color: $color-white;
    height: 100%;
    padding: 0.875rem 1rem;
    overflow-x: hidden;
    
  }

  .MuiModal-root {
    position: absolute;
    width: 30%;
    height: 100%;
    margin-left: 24%;
    padding: 0rem 0.625rem 0rem 0.625rem;
    background: $color-white;
    box-shadow: 0rem 0.25rem 1.25rem $color-black-light;
    align-items: center;
    opacity: 8;
  }

  .notes-header {
    align-items: center;
    padding: 0rem;
    height: 2.375rem;
    left: 0rem;
    top: 0rem;
    background: $color-white;
    display: flex;
    border-bottom: 0.063rem solid $color-medium-gray;
    color: $color-secondary;

    span{
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      color: $color-secondary;
    }

    .notes-icons {
      margin: auto 0 auto auto;
      position: relative;
      cursor: pointer;
    }
  }

  .Flyout-root {
    width: 100%;
    height: fit-content;
    left: 0rem;
    border-radius: 0.375rem;
    border-top: 5.625rem;

    .k-button-solid-base.k-selected {
      border: none;
      background-color: $color-primary-grey;
    }
  }

  .notes-save {
    background: $color-light-gray;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 0.0625rem solid $color-disabled-light;
    border-radius: 0rem 0rem 0.375rem 0.375rem;
    position: relative;
    cursor: pointer;

    h3 {
      margin: 0.75rem 0.8rem;
      font-size: 0.875rem;
      font-weight: 500;
      position: relative;
      cursor: pointer;
      left: 0.625rem;
    }

    .save-btn {
      cursor: pointer;
      padding-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .notes-section {
    margin: 2rem 4rem 0rem 0rem;
    width: 100%;
    background: $color-white;
    border: none;
    border-radius: 0.9rem;

    .MuiFormControl-root {
      width: 100%;
      border-radius: 0.9rem;
    }

    .workup-root {
      display: flex;
      margin-bottom: 0.635rem;
      padding: 0.2rem;
      box-sizing: border-box;

      .left-sec {
        .MuiTextField-root.search {
          .MuiOutlinedInput-root {
            border-radius: 3.125rem;
            width: 36rem;
          }

          .Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border: 0.063rem solid $color-gray;
            }
          }

        }
      }
    }
  }

  .notes-content {
    margin: 0.625rem 0rem 0rem 0.5rem;
    .noNotes{
      margin: 9rem;
      color: $color-lightest-grey;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
    }
    .notes-bg {
      background: $color-light-gray;
      margin-top: 1rem;
      width: 100%;
      border-radius: 0.375rem;
      color: $color-secondary;

      .MuiGrid-root.MuiGrid-item {
        .quill {
          .ql-container {
            font-size: 1rem;
            font-family: "Rubik", sans-serif;
          }
        }
      }

      .name-dis {
        display: flex;
        font-size: 0.875rem;
        align-items: center;
        justify-items: flex-start;
        background: $color-light-gray;
        width: 90%;
        padding: 0.7rem 1rem 0.1rem 1rem;

        .MuiGrid-root.MuiGrid-item {
          width: 20%;
          float: right;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5rem;

          .MuiButtonBase-root.MuiIconButton-root {
            float: right;
            margin-left: 1rem;
          }
        }

        .fName-lName {
          display: flex;
          margin-left: 0rem;
          flex-direction: row;
          font-weight: 500;
          min-height: 1rem;
          width: max-content;
          white-space: nowrap;
          font-weight: 500;
          line-height: 1.313rem;
        }

        .date-time {
          position: relative;
          padding-left: 1rem;
          width: 70%;
          color: $color-darker-gray;
        }
      }

      .Flyout-comment {
        left: 0rem;
        display: flex;
        border-radius: 0rem;
      }

      .action-strap {
        height: 1.875rem;
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        align-items: center;
        border: 0.063rem solid $color-disabled-light;
        background: $color-light-gray;

        .icons {
          padding-left: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }
}

.edit-delet-workup {
  .MuiPaper-root.MuiPaper-elevation5.MuiPopover-paper {
    max-width: 9.375rem;
    margin-left: 0;
  }
}
.workup-notes-pop::-webkit-scrollbar{
  width: 0;
}