@import "../../variables";

.session-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 30%;
    margin: 13rem 0 0 25rem;
    height: 10rem;
    z-index: 999;
    opacity: 1;

    .card-content {
        margin-right: auto;
        margin-left: auto;
        width: 31rem;
        border-radius: 0.313rem;
        padding: 0.625rem;
        text-align: center;

        .session-header {
            display: flex;
            direction: row;
            align-items: center;
            position: relative;
            bottom: 1.5rem;
            border-bottom: 0.091rem solid $color-light-white;
            line-height: 1.5rem;
            font-weight: 500;
            color: $color-secondary;
            .close-icon {
                margin-left: auto;
                cursor: pointer;
            }
        }

        .session-content {
            justify-content: start;
            text-align: start;
            position: relative;
            color: $color-secondary;
            font-size: 0.875rem;
            line-height: 1.3rem;
            font-weight: 400;
            padding-bottom: 2rem;
            .timer{
                color: $color-primary;
            }
        }

        .session-action {
            justify-content: end;
            position: relative;
            font-size: 0.875rem;

            .logout-btn {
                padding: 0.5rem 2rem;
                background: $color-white;
                border-radius: 0.313rem;
                color: $color-secondary;
                border: 0.0625rem solid $color-secondary;
                text-transform: none;
                line-height: 1.125rem;

            }

            .extend-btn {
                padding: 0.5rem 2rem;
                background: $red;
                border-radius: 0.313rem;
                color: $color-white;
                text-transform: none;
                line-height: 1.125rem;

            }

        }
    }
}